import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const WeatherDataPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="How NEWA Handles Weather Data" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>How NEWA Handles Weather Data</h1>
            <p className="intro">
              Accurate and reliable weather data are important for good farm
              management.
            </p>
            <h2>Data quality control</h2>
            <p>
              Quality control methods are applied to sensor data coming from the
              weather stations linked with NEWA using a suite of protocols.
              These methods ensure datasets used by NEWA crop and IPM tools are
              precise and accurate.
            </p>
            <h3>Station outage reports</h3>
            <p>
              The NEWA Help Desk notifies weather station owners by email when a
              data outage occurs. Messages are sent to station owners and
              maintenance contacts after outage periods of 24 hours, 7 days, and
              21 days. The weather station is removed temporarily (status
              inactive) from the NEWA website after 21 days but is reinstated
              after the problem is fixed. 
            </p>
            <h3>Weather station status reporting</h3>
            <p>
              All NEWA State Coordinators receive a daily status summary of all
              NEWA weather stations in their state. These reports include a list
              of stations with their respective timestamps of last reported
              data. The weather stations are grouped into categories of those
              reporting within the last 24 hours; those with no data reported in
              the last 24 hours; and those that have not reported for more than
              21 days (status inactive). The State Coordinator can then follow
              up with station owners or make referrals to the NEWA Help Desk if
              needed. 
            </p>
            <h3>Missing weather variables</h3>
            <p>
              <em>Temperature and relative humidity</em> data points
              occasionally are missing in a data record and NEWA attempts to
              estimate missing values following a two-step process.
            </p>
            <ol>
              <li>
                The data record is examined for non-missing temperature or
                relative humidity values in the previous hour and the next hour.
                If both are found, an average of the two is used as an estimate.
                 
              </li>
              <li>
                If the previous hour and the next hour values are unavailable,
                temperature or relative humidity data from the National Centers
                for Environmental Prediction’s Real-Time Mesoscale Analysis
                (RTMA) and Unrestricted Mesoscale Analysis (URMA) for the
                nearest location grid are used.
              </li>
            </ol>
            <p>
              <em>Precipitation and wind direction </em>missing data are sourced
              from the RTMA and URMA archive.
            </p>
            <p>
              <em>Solar radiation </em>missing data are estimated based on sky
              cover data from the RTMA and URMA archive.
            </p>
            <p>
              <em>Soil temperature and soil moisture</em> measurements are
              location dependent and are not substituted or estimated in any
              scenario.
            </p>
            <p>
              Estimated data are displayed as brown italics in the weather data
              listings on NEWA. Currently, no other weather variables, other
              than forecast data, are estimated in the NEWA weather data
              listings.
            </p>
             <h2>Data use in NEWA crop & IPM tools</h2>
            <h3>Leaf wetness</h3>
            <p>
              Leaf wetness is an important measurement for some NEWA tools, but
              airport locations and some other weather stations do not have
              these sensors. For these stations, NEWA records an hour of leaf
              wetness when the average relative humidity in that hour is greater than 90%. 
            </p>
            <p>
              In the NEWA tools that use leaf wetness hours, a wet hour is
              logged when ≥ 1 minute of sensor leaf wetness is recorded.
            </p>
            <h3>Relative humidity</h3>
            <p>
              Relative humidity (RH) data recorded at airport weather stations
              and those from NWS RH forecast values are usually not
              representative of conditions in an agricultural setting.
              Therefore, these RH values are adjusted using the formula: adjRH =
              RH / (0.0047*RH + 0.53).
            </p>
            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>
                Page updated June 2020 by D. Olmstead, K. Eggleston and J.
                Carroll
              </p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WeatherDataPage
